$color_1: #d71414;
$color_2: #FFF;
$color_3: #f38321;
$color_4: #999;
$color_5: #222;
$color_6: rgb(255, 255, 255);
$color_7: #fff;
$color_8: #ff9900;
$color_9: #AAA;
$color_10: #BFB6CD;
$color_11: orange;
$font-family_1: Montserrat, sans-serif;
$background-color_1: #080808;
$background-color_2: rgba(0, 0, 0, 0);
$background-color_3: rgba(0, 0, 0, 0.6);
$background-color_4: #b5bcc4;
$background-color_5: rgba(0, 0, 0, 0.8);
$background-color_6: transparent;
$border-color_1: #f38321;

@import "https://fonts.googleapis.com/css?family=Montserrat:400,800";

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.colored {
  color: $color_1;
}

.brand-text {
  color: $color_2;
  font-family: $font-family_1;
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 2px;
  text-decoration: none;
  margin-left: 60px;
  margin-top: -45px;
}

a {
  color: $color_2;
  font-family: $font-family_1;
  text-decoration: none;

  &:visited {
    color: $color_2;
    text-decoration: none;
  }

  &:hover {
    color: $color_3;
    text-decoration: none;
  }

  &:active {
    color: $color_2;
    text-decoration: underline;
  }

  &:focus {
    color: $color_2;
    text-decoration: none;
  }
}

.landing_button_container {
  img {
    transition: transform 1s ease;

    &:hover {
      animation: pulse 1s infinite;
      transform: scale(1.1);
    }

    &:focus {
      scale: 1;
    }
  }
}

.img-logo {
  max-width: 30%;
  height: auto;
}

html {
  color: $color_2;
  background-color: $background-color_1;
  font-size: 16px;
  line-height: 1.5rem;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  color: $color_2;
  background-color: $background-color_1;
  font-size: 16px;
  line-height: 1.5rem;
  max-width: 100%;
  overflow-x: hidden;
}

.full-width {
  padding-left: 0;
  padding-right: 0;
  background-size: cover;
  background-position: center;
  padding: 25vh 0;
  border-bottom: 0px solid #000;
}

h1 {
  font-family: $font-family_1;
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

h2 {
  font-family: $font-family_1;
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

h3 {
  font-family: $font-family_1;
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

h4 {
  font-family: $font-family_1;
  font-weight: 600;
  margin-bottom: 1rem;
}

h5 {
  font-family: $font-family_1;
  font-weight: 600;
  margin-bottom: 1rem;
}

h6 {
  font-family: $font-family_1;
  font-weight: 600;
  margin-bottom: 1rem;
}

p {
  font-family: $font-family_1;
  font-size: 0.9375rem;
  padding: 5px 5px 5px 0;
  margin: 0;
  font-weight: 400;
}

.strong {
  font-weight: bold;
}

.spaced {
  letter-spacing: 5px;
}

.subtle {
  color: $color_4;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.short-hr-left {
  &::after {
    border-top: 2px solid;
    border-color: $border-color_1;
    content: "";
    display: block;
    height: 1px;
    width: 10%;
    margin: 13px 0 0 0;
  }
}

.short-hr-center {
  &::after {
    border-top: 2px solid;
    border-color: $border-color_1;
    content: "";
    display: block;
    height: 1px;
    width: 100px;
    margin: 10px auto 0 auto;
  }
}

.short-hr-right {
  &::after {
    border-top: 2px solid;
    border-color: $border-color_1;
    content: "";
    display: block;
    height: 1px;
    width: 10%;
    margin: 10px 0 0 auto;
  }
}

ul {
  list-style: none;
  padding: 0;
}

li {
  font-family: $font-family_1;
}

.floated-left {
  float: left;
}

.floated-right {
  float: right;
}

.inline {
  display: inline;
}

.tiny-margin {
  margin-bottom: 3.625em;
}

.small-margin {
  margin-bottom: 6em;
}

.medium-margin {
  margin-bottom: 8em;
}

.large-margin {
  margin-bottom: 12em;
}

hr {
  width: 95%;
  height: 1px;
  background: #d71414;
  margin: 20px auto 25px auto;
}

button {
  outline: none !important;
}

.button {
  height: auto;
  width: 200px;
  margin: 40px auto;
  padding: 10px 15px;
  font-size: 1rem;
  font-family: $font-family_1;
  color: $color_5;
  border: none;
  border-radius: 8px;
  background: rgb(255, 105, 16);
  display: inline-block;
  outline: none;
  transition: all 0.2s ease;
  cursor: pointer;
  text-align: center;

  &:visited {
    color: $color_6;
  }

  &:hover {
    text-decoration: none !important;
    color: $color_2 !important;
    background: #b15000;
  }

  &:focus {
    color: $color_5;
  }
}

.help-block {
  font-size: 12px;
}

.cookies-bar {
  background: #111;
  height: auto;
  width: 100vw;
  position: fixed;
  bottom: -60px;
  border-top: 1px solid #f38321;
  z-index: 10;
  transition: 0.4s;

  p {
    display: inline;
  }

  a {
    text-decoration: underline;
  }

  .button {
    display: block;
    position: absolute;
    top: 25%;
    right: 35px;
    margin: 0;
    height: 30px;
    width: 30px;
    padding: 0;
    font-size: 1.4rem;
    color: $color_7;
    background: none;

    &:hover {
      color: $color_8 !important;
    }
  }
}

.cookies-wrapper {
  position: relative;
  padding: 15px 40px;
}

.cookies-bar.open {
  transform: translateY(-60px);
}

.cookies-bar.closed {
  transform: translateY(60px);
}

#loader-wrapper {
  background: #111;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  text-align: center;
  z-index: 1050;
}

.loader-logo {
  position: relative;
  color: $color_2;
  top: 43%;
  font-family: $font-family_1;
  letter-spacing: 5px;
}

#progress {
  width: 0;
  height: 2px;
  background: #0051ff;
  position: relative;
  top: 45%;
}

.loader-text {
  position: relative;
  color: $color_2;
  top: 48%;
}

.loaded {
  -moz-transform: translate3d(0px, -100%, 0px);
  -webkit-transform: translate3d(0px, -100%, 0px);
  -o-transform: translate(0px, -100%);
  -ms-transform: translate(0px, -100%);
  transform: translate3d(0px, -100%, 0px);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#main-logo {
  color: $color_2;
  font-family: $font-family_1;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 2px;
  margin: 0 0px;
  text-decoration: none;
  text-align: justify;
}

.navbar-dark {
  background-color: $background-color_2;
  border: 1px solid rgba(0, 0, 0, 0);
  height: auto;

  .navbar-nav {
    .nav-link {
      color: $color_2;

      &:hover {
        color: $color_1;
      }
    }
  }
}

.fixed-top {
  z-index: 90;
}

.scrolled {
  background-color: $background-color_3;
  z-index: 90;
}

.navbar-collapse {
  .dropdown-menu {
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    background-color: $background-color_3;
  }

  .dropdown-item {
    color: $color_7;
    text-decoration: none;

    &:hover {
      background: none;

      .item-text {
        color: $color_1;
      }
    }

    .item-text {
      font-size: 0.875rem;
    }
  }

  .dropdown-items-divide-hr {
    width: 100%;
    height: 1px;
    margin: 0.25rem auto 0.25rem auto;
    border: none;
    background-color: $background-color_4;
    opacity: 0.2;
  }
}

.navbar-toggler {
  border: none;
  cursor: pointer;
}

.icon-social {
  transition: 0.3s ease;

  &:hover {
    scale: 1.3;
  }
}

.fa-discord {
  transition: 0.3s ease;

  &:hover {
    scale: 1.3;
  }
}

#hamburger {
  .icon-bar {
    display: block;
    height: 2px;
    width: 25px;
    background: #FFF;
    margin: 7px 0;
    transition: .3s ease-in-out;

    &:nth-child(1) {
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(2) {
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(3) {
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }
}

#hamburger.open {
  .icon-bar {
    &:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    &:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}

#container_platforms_btns {
  max-width: 100%;
  height: auto;
  justify-content: center;
  border: 10px;
  display: inline-block;
  padding: 10px;
  margin-top: 1vh;
}

#container_playnow_btn {
  max-width: 100%;
  height: 50;
  margin: 0px;
  display: flex;
  justify-content: center;
  top: 50%;
}

#container_buynow_btn {
  max-width: 100%;
  height: 50;
  margin: 0px;
  display: flex;
  justify-content: left;
  top: 50%;
}

.hero-unit {
  position: relative;
  border-bottom: 1px solid #000;
}

#bgvid {
  width: 100%;
  height: 100%;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $background-color_3;
}

.hero-caption {
  position: absolute;
  top: 20%;
  left: 15%;
  right: 15%;
  padding: 15px;
  z-index: 5;
  text-align: center;

  h2 {
    font-size: 1.5rem;
  }

  h1 {
    font-size: 3.5rem;
  }
}

.headline {
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 65%;
}

#about-section {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%), url(../images/base/placeholders/chapters/chapter2_bg.png);
  padding: 10vh;
}

.features {
  .col-md-4 {
    background-size: cover;
    background-position: center;
    min-height: 550px;
    padding: 10vh 5vw;
  }

  h2 {
    margin-top: 30vh;
    transition: 0.4s ease;
  }

  h2.short-hr-center {
    &::after {
      transition: 0.4s ease;
      width: 0;
    }
  }

  p {
    opacity: 0;
    transition: 0.4s ease;
  }
}

#features-section {
  .feature-card {
    &:nth-child(1) {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../images/base/placeholders/feature.jpg);
    }

    &:nth-child(2) {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../images/base/placeholders/feature.jpg);
    }

    &:nth-child(3) {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../images/base/placeholders/feature.jpg);
    }
  }
}

.card-active {
  h2 {
    margin-top: 20vh;
  }

  h2.short-hr-center {
    &::after {
      width: 100px;
    }
  }

  p {
    opacity: 1;
  }
}

.feature-card {
  border-left: 1px solid rgb(255, 255, 255);
  border-right: 1px solid rgb(255, 255, 255);
  border-bottom: 2px solid rgb(255, 255, 255);
}

.extra-features {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%), url(../images/base/placeholders/chapters/chapter1_bg.png);
  background-size: cover;
  border-bottom: 2px solid rgb(255, 255, 255);
  background-attachment: fixed;

  &:nth-of-type(3) {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%), url(../images/base/placeholders/chapters/chapter2_bg.png);
    background-attachment: fixed;
  }

  &:nth-of-type(4) {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%), url(../images/base/placeholders/chapters/chapter3_bg.png);
    background-attachment: fixed;
  }

  img {
    margin: 10vh auto 0 auto;
  }

  .col-xl-8 {
    padding: 30vh 10% 25vh 10px;
  }
}

.extra-features.right {
  text-align: right;

  .col-xl-8 {
    padding: 30vh 10px 25vh 10%;
  }
}

.gallery-item {
  padding: 0;
  border: 0px solid rgb(255, 255, 255);

  img {
    width: 100%;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: 0.3s ease;

  &:hover {
    opacity: 0;
  }
}

#cta-section {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(../images/base/placeholders/chapters/chapter2_bg.png);
  padding: 8vh;

  h1 {
    font-size: 3.0rem;
  }
}

.game-box {
  float: right;
  width: 80%;
  height: auto;
}

.platforms {
  max-width: 100%;
  height: auto;
}

#dlc-section {
  .col-xl-6 {
    &:nth-of-type(1) {
      padding-right: 0;
    }

    &:nth-of-type(2) {
      background: url(../images/bg.png) repeat;
    }
  }

  img {
    float: right;
  }

  .button {
    margin: 20px 5px;
  }
}

.dlc-card {
  padding: 10% 15px;
}

#extras {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(../images/base/placeholders/bg.jpg);

  .headline {
    margin-top: 20vh;
    width: 70%;
  }

  .social-links {
    li {
      display: inline-block;
      border: 2px solid #FFF;
      border-radius: 50%;
      padding: 12px;
      margin: 0 5px;

      i {
        vertical-align: middle;
        text-align: center;
        width: 25px;
        height: 15px;
      }

      &:hover {
        background: #FFF;

        a {
          color: $color_3;
        }
      }
    }
  }
}

input {
  width: 60%;
  height: 45px;
  border: none;
  border-radius: 4px;
  background: #222;
  padding: 0 0 0 20px;
  outline: #333;
  color: $color_2;
}

#newsletter-box {
  h2 {
    margin-bottom: 20px;
  }

  .button {
    color: $color_2;
    background: #222;
    border: none;
    margin: 0;
    height: 45px;
    vertical-align: top;

    &:hover {
      background: #bc1a1a;
    }
  }
}

.text-danger {
  font-family: $font-family_1;
  font-size: 1.3rem;
  margin-top: 10px;
}

.text-success {
  font-family: $font-family_1;
  font-size: 1.3rem;
  margin-top: 10px;
}

.upper-social {
  font-size: 12px;
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  background-color: $background-color_6;
  transition: 0.1s background-color linear, 0.1s color linear;
  padding: 10px 20px;
  margin-right: 1vh;
  margin-top: 1vh;
  font-family: $font-family_1;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
}

.social-links {
  margin-top: 2vh;
}

#footer {
  width: 100%;
  min-height: 50px;
  height: auto;
  color: $color_9;
  background: url(../images/base/bg_footer.png) repeat;
  border-top: 0px solid #f38321;
  font-size: 14px;
  line-height: 20px;

  .row {
    &:only-child {
      padding: 20px 0;
    }
  }

  ul {
    margin-left: 0px 0;

    li {
      display: inline-block;
      margin-bottom: 10px;
      margin-left: 0px;

      a {
        color: $color_10;

        &:hover {
          color: $color_3;
        }
      }
    }
  }
}

.brands {
  text-align: auto;

  img {
    width: auto;
    height: 50px;
    border: 15px;
    margin-left: 15px;
  }
}

#footerinfo {
  transition: 0.3s ease;
  font-size: 12px;
  color: $color_10;
  margin-bottom: 0px;
  margin-top: 10px;
  margin-left: 15px;
  text-decoration: underline;

  &:hover {
    color: $color_11;
  }
}

#copyright {
  font-size: 12px;
  color: $color_10;
  margin-bottom: 0px;
  margin-top: 10px;
  margin-left: 15px;

  a {
    color: $color_10;
    text-decoration: underline;
  }
}

.extra-page {
  font-size: 0.875rem;

  a {
    color: $color_1;

    &:hover {
      color: $color_1;
      text-decoration: underline;
      text-decoration-color: #d71414;
    }
  }

  .hero-unit {
    min-height: 400px;
    background: #222;
    border: none;
  }

  .hero-caption {
    margin-top: 25vh;

    h1 {
      font-size: 3.5rem;
    }
  }
}

.text-container {
  ul {
    list-style: circle;
    margin-left: 40px;

    li {
      margin: 5px 0;
    }
  }

  ol {
    li {
      margin: 5px 0;
    }
  }
}

.breadcrumb-nav {
  background: #171717;
  border: none;
  height: 60px;
}

.breadcrumb {
  border: none;
  background: none;
  margin: 0;
  height: 60px;

  li {
    padding-top: 5px;
  }
}

#footer-extra {
  padding-top: 25px;
  border-top: 1px solid #d71414;
  background: url(../images/bg.png) repeat;

  .social-links {
    li {
      display: inline-block;
      border: 2px solid #FFF;
      border-radius: 50%;
      padding: 8px;
      margin: 0 5px;

      i {
        vertical-align: middle;
        text-align: center;
        width: 25px;
        height: 15px;
      }

      a {
        color: $color_7;
      }

      &:hover {
        background: #FFF;

        a {
          color: $color_1;
        }
      }
    }
  }
}

.form-control-input {
  width: 100%;
}

.form-control-select {
  width: 100%;
  height: 45px;
  background: #222;
  border: none;
  outline: #333;
  color: $color_7;
  padding: 0 0 0 20px;
}

.checkbox {
  font-size: 14px;

  input {
    width: auto;
    height: auto;
    vertical-align: -5%;
    margin-right: 0.375rem;
  }
}

#privacyForm {
  .button {
    width: 100%;
    margin-top: 0;
    height: 50px;
    font-size: 1.1rem;
  }
}

.form-container {
  padding-top: 2em;
}

@media (max-width : 767px) {
  .tiny-margin {
    margin-bottom: 1.5em;
  }

  .small-margin {
    margin-bottom: 3em;
  }

  .medium-margin {
    margin-bottom: 4em;
  }

  .large-margin {
    margin-bottom: 6em;
  }

  .cookies-wrapper {
    padding: 15px 60px 15px 20px;
  }

  .cookies-bar {
    .button {
      top: 30%;
    }
  }

  .hero-caption {
    h1 {
      font-size: 1.0rem;
    }

    h2 {
      font-size: 1.0rem;
    }
  }

  .extra-features {
    .col-xl-8 {
      padding: 30vh 15px;
    }
  }

  .extra-features.right {
    .col-xl-8 {
      padding: 30vh 15px;
    }
  }

  .game-box {
    float: none;
    margin-bottom: 3vh;
  }

  .cta-card {
    text-align: center;
  }

  #dlc-section {
    padding: 5% 0;
    text-align: center;

    .short-hr-left {
      &:after {
        margin: 10px auto;
      }
    }
  }
}

@media (max-width : 991px) {
  .navbar-dark {
    background-color: $background-color_3;
  }

  .scrolled {
    background-color: $background-color_5;
    height: auto;
  }

  .full-width {
    padding: 20vh 0;
  }

  .navbar-collapse {
    .dropdown-menu {
      background: none;
    }
  }

  #extras {
    text-align: center;
    padding: 10vh 15px;

    .headline {
      margin: 0 0 3em 0;
    }
  }

  #footer-extra {
    .col-md-4 {
      margin-bottom: 2em;
      text-align: center;
    }

    .row {
      &:first-child {
        padding: 40px 0 20px 0;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  #container_platforms_btns {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

@media (max-widtH : 988px) {
  .reviews {
    margin-top: 1em;
  }
}

@media (min-width: 1366px) {
  .features {
    h2 {
      margin-top: 25%;
    }
  }

  .card-active {
    h2 {
      margin-top: 35%;
    }
  }
}

@media (max-width : 1199px) {
  .extra-features {
    img {
      display: none;
    }

    .col-xl-8 {
      padding: 25vh 15vh;
    }
  }

  .extra-features.right {
    .col-xl-8 {
      padding: 25vh 15vh;
    }
  }

  #dlc-section {
    .col-xl-6 {
      &:nth-of-type(1) {
        padding: 0;
      }
    }

    img {
      width: 100%;
      float: none;
    }
  }
}